<template>
  <div>
    <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuNotifications"
        sectionID="notifications"
        :showCount="false"
        :showValue="false"
        :showFilters="false"
        :showSort="false"
        :showSellButton="false"
        :totalCount="0"
        :totalValue="0"
      />
    </div>

    <b-card class="bg-transparent border-0">
      <b-card-body>
        <b-table
          striped
          hover
          :items="notification"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        ></b-table>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";

export default {
  components: { TheTitleBar },
  data() {
    return {
      screenWidth: window.innerWidth,
      notification: [],
      sortBy: "date",
      sortDesc: true,
      fields: [
        {
          key: "date",
          label: "Date & Time",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          thClass: "text-primary"
        },
        {
          key: "id",
          label: "Listing ID",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          tdClass: "text-primary",
          thClass: "text-primary"
        },
        {
          key: "details",
          label: "Details",
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          thClass: "text-primary"
        }
      ]
    };
  },
  methods: {
    async getNotifications() {
      try {
        const response = await axios.post("SP/post/sp_LogNotification_Get", {
          UpdatedBy: this.$store.getters.user.UserId
        });

        JSON.parse(response.data.result).Table.forEach(element => {
          let table = {
            date:
              element.ln_DateTime.split("T")[0] +
              " " +
              element.ln_DateTime.split("T")[1].split(".")[0],
            id: element.ln_RefrenceId ? element.ln_RefrenceId : "",
            details: element.ln_Notification ? element.ln_Notification : ""
          };

          this.notification.push(table);
        });
      } catch (error) {
        console.log(error.response.data);
      }
    }
  },
  beforeMount() {
    this.getNotifications();
  }
};
</script>
